
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    to: {
      default: () => {
        return { name: 'dashboard' } as any;
      },
    },
    text: { default: 'Back to overview' },
  },
});
