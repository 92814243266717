
import { defineComponent } from 'vue';
import Avatar from '@/components/avatars/Avatar.vue';
import UploadProfile from '@/components/modals/account/UploadProfileModal.vue';
import { mapActions, mapGetters } from 'vuex';
import {
  Getters as AuthGetters,
  Actions as AuthActions,
} from '@/store/enums/AuthEnums';
import mask from '@/utils/mask';
import toasts from '@/utils/toasts';
import parser from '@/utils/parser';
import Navigator from '@/views/new-design/layout/Navigator.vue';
import { User } from '@/models/UserModel';
import text from '@/utils/text';

export default defineComponent({
  name: 'profile',
  components: {
    Avatar,
    Navigator,
    UploadProfile,
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      // profilePicture: AuthGetters.GET_AUTH_USER_PROFILE_PICTURE,
      actionErrors: AuthGetters.GET_AUTH_USER_ACTION_ERROR,
    }),
    mobileMasking() {
      return mask.mobile.aus;
    },
    userRole() {
      const authUser = this.authUser as User;

      return parser.userRole(authUser.roles);
    },
    avatar() {
      const authUser = this.authUser as User;
      return authUser?.avatar_src;
    },
    acronym() {
      const authUser = this.authUser as User;

      const firstName = (authUser || {})?.first_name;
      const lastName = (authUser || {})?.last_name;

      if (firstName === '' && lastName === '')
        return text.acronym((authUser || {})?.email);

      return text.acronym(`${firstName} ${lastName}`);
    },
  },
  methods: {
    ...mapActions({
      updateProfile: AuthActions.UPLOAD_PROFILE_PICTURE,
      removeAvatar: AuthActions.REMOVE_PROFILE_PICTURE,
    }),

    canAccess() {
      return this.authUser?.roles.includes('principal');
    },

    handleUpload(values) {
      if (!values || values == '') {
        return;
      }

      const accepted = ['image/png', 'image/gif', 'image/jpeg'];

      if (!accepted.includes(values.type)) {
        toasts.error({}, 'Invalid file type.');
        return false;
      }

      this.updateProfile(values)
        .then(async () => {
          toasts.success('Profile Successfully Updated');
          (this.$refs as any).uploadAvatarModal.clearUploadValue();
        })
        .catch(() => {
          const { errors, message } = this.actionErrors;
          toasts.error(errors, message);
        });
    },
    async handleRemoveAvatar() {
      await this.removeAvatar()
        .catch(() => {
          const { errors, message } = this.actionErrors;
          toasts.error(errors, message);
        })
        .finally(() => {
          (this.$refs as any).uploadAvatarModal.clearUploadValue();
        });
    },
  },
});
