
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';

import Avatar from '@/components/avatars/Avatar.vue';

export default defineComponent({
  name: 'upload-profile-modal',
  data: () => ({
    modal: {} as HTMLElement,
    editMode: false,
    file: '',
    imageSource: '',
  }),
  components: {
    Avatar,
  },
  props: {
    profilePicture: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      // profilePicture: AuthGetters.GET_AUTH_USER_PROFILE_PICTURE,
    }),
    defaultAbbreviation() {
      return `${this.authUser.first_name.charAt(
        0
      )}${this.authUser.last_name.charAt(0)}`.toUpperCase();
    },
  },
  emits: ['changeProfileForm', 'removeProfileAvatar'],
  mounted() {
    // setup the modal reference and event listener
    this.modal = document.getElementById(
      't2o_profile_form_modal'
    ) as HTMLElement;

    if (this.modal) {
      this.modal.addEventListener('hidden.bs.modal', () => {
        this.imageSource = this.profilePicture as any;
        this.editMode = false;
      });
    }
  },
  methods: {
    onFileHandle(e) {
      this.editMode = true;
      this.file = e.target.files[0] ?? '';
      this.imageSource = URL.createObjectURL(e.target.files[0]);
    },
    remove() {
      // this.imageSource = '';
      this.editMode = false;
      this.$emit('removeProfileAvatar');
    },
    save() {
      this.editMode = false;
      this.$emit('changeProfileForm', this.file);
    },
    handleUploadClick() {
      const uploadFileInput = document.getElementById(
        'upload-file'
      ) as HTMLElement;

      uploadFileInput.click();
    },
    clearUploadValue() {
      (this.$refs as any).uploadInput.value = null;
    },
  },
  watch: {
    profilePicture: {
      handler(values) {
        this.imageSource = values;
      },
      immediate: true,
      deep: true,
    },
  },
});
