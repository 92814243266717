export const business = {
  abn: 'XX XXX XXX XXX',
  acn: 'XXX XXX XXX',
};

export const mobile = {
  aus: 'XXXX XXX XXX',
};

export const phone = {
  aus: 'XX XXXX XXXX',
};

export const postCode = {
  aus: 'XXXX',
};

export const date = {
  aus: 'XX/XX/XXXX',
};

export default {
  business,
  mobile,
  phone,
  postCode,
  date,
};
